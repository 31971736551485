export const links = [
  // Dashboard
  {
    id: 'dashboard',
    label: 'menu.dashboard.title',
    exact: true,
    activeClassName: 'active',
    icon: 'tachometer-alt',
    to: {
      pathname: '/dashboard',
    },
  },
  // Analytics
  {
    id: 'analysis',
    label: 'menu.analytics.title',
    exact: true,
    activeClassName: 'active',
    icon: 'analytics',
    to: {
      pathname: '/analysis',
    },
    children: [
      // Analysis Process
      {
        id: 'analysis-process',
        label: 'menu.analytics.submenu.analysisProcess',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/analysis-process',
        },
      },
      // Event Manager
      {
        id: 'event-manager',
        label: 'menu.analytics.submenu.eventManager',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/event-manager',
        },
      },
      // Correlation
      {
        id: 'correlation',
        label: 'menu.analytics.submenu.correlation',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/correlation',
        },
      },
      // Operator
      {
        id: 'analysis-operator',
        label: 'menu.analytics.submenu.operator',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/operator',
        },
      },
      // Comparative Between Periods
      {
        id: 'comparative-between-periods',
        label: 'menu.analytics.submenu.comparativeBetweenPeriods',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/comparative-between-periods',
        },
      },
      // Report per Periods
      {
        id: 'report-per-periods',
        label: 'menu.analytics.submenu.reportPerPeriods',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/analysis/report-per-periods',
        },
      },
    ],
  },
  // DataMining
  {
    id: 'data-mining',
    label: 'menu.dataMining.title',
    exact: true,
    activeClassName: 'active',
    icon: 'abacus',
    to: {
      pathname: '/data-mining',
    },
    children: [
      // Regression
      {
        id: 'regression',
        label: 'menu.dataMining.submenu.regression',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/data-mining/regression',
        },
      },
      // Clustering
      {
        id: 'clustering',
        label: 'menu.dataMining.submenu.clustering',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/data-mining/clustering',
        },
      },
    ],
  },
  // Control Loop Management
  {
    id: 'control-loop-management',
    label: 'menu.controlLoopManagement.title',
    exact: true,
    activeClassName: 'active',
    icon: 'sliders-v',
    to: {
      pathname: '/control-loop-management',
    },
    children: [
      // Performance
      {
        id: 'control-loop-management-performance',
        label: 'menu.controlLoopManagement.submenu.performance',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/control-loop-management/performance',
        },
      },
      // Overview
      {
        id: 'control-loop-management-overview',
        label: 'menu.controlLoopManagement.submenu.overview',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/control-loop-management/overview',
        },
      },
    ],
  },
  // Statistical Control
  {
    id: 'statistical-control',
    label: 'menu.statisticalControl.title',
    exact: true,
    activeClassName: 'active',
    icon: 'chart-bar',
    to: {
      pathname: '/statistical-control',
    },
    children: [
      {
        id: 'statistical-control-exploratory',
        label: 'menu.statisticalControl.submenu.exploratory',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/statistical-control/exploratory',
        },
      },
      {
        id: 'statistical-control-production',
        label: 'menu.statisticalControl.submenu.production',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/statistical-control/production',
        },
      },
      {
        id: 'statistical-control-management',
        label: 'menu.statisticalControl.submenu.management',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/statistical-control/management',
        },
      },
    ],
  },
  {
    id: 'root-cause',
    label: 'menu.rootCause.title',
    exact: true,
    activeClassName: 'active',
    icon: 'crosshairs',
    to: {
      pathname: '/root-cause',
    },
    children: [
      {
        id: 'root-cause-effects-page',
        label: 'menu.rootCause.submenu.actionsAndPlans',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/root-cause/effects-page',
        },
      },
      {
        id: 'root-cause-generate-analysis',
        label: 'menu.rootCause.submenu.generateAnalysis',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/root-cause/generate-analysis',
        },
      },
      {
        id: 'root-cause-action-list',
        label: 'menu.rootCause.submenu.actionList',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/root-cause/action-list',
        },
      },
    ],
  },
  // Equipment Management
  {
    id: 'equipment-management',
    label: 'menu.equipmentManagement.title',
    exact: true,
    activeClassName: 'active',
    icon: 'industry-alt',
    to: {
      pathname: '/equipment-management',
    },
    children: [
      {
        id: 'stopping-point',
        label: 'menu.equipmentManagement.submenu.stoppingPoint',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/equipment-management/stopping-point',
        },
      },
      {
        id: 'stopping-chart',
        label: 'menu.equipmentManagement.submenu.stoppingChart',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/equipment-management/stopping-chart',
        },
      },
      {
        id: 'approve-stop',
        label: 'menu.equipmentManagement.submenu.approveStop',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/equipment-management/approve-stop',
        },
      },
    ],
  },
  // OPs Management
  {
    id: 'ops-management',
    label: 'menu.opsManagement.title',
    exact: true,
    activeClassName: 'active',
    icon: 'business-time',
    to: {
      pathname: '/ops-management',
    },
  },
  // Gestão Integrada de Manutenção
  {
    id: 'integrated-maintenance',
    label: 'menu.integratedMaintenance.title',
    exact: true,
    activeClassName: 'active',
    icon: 'wrench',
    to: {
      pathname: '/integrated-maintenance',
    },
    children: [
      // Painel de OS
      {
        id: 'service-order-panel',
        label: 'menu.integratedMaintenance.submenu.serviceOrderPanel',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/service-order-panel',
        },
      },
      // Programação de OS
      {
        id: 'service-order-programming',
        label: 'menu.integratedMaintenance.submenu.serviceOrderProgramming',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/service-order-programming',
        },
      },
      // Geração de Os
      {
        id: 'service-order-generator',
        label: 'menu.integratedMaintenance.submenu.osGenerator',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/service-order-generator',
        },
      },
      // Apontamento de Mão de Obra
      {
        id: 'service-order-labor-appointment',
        label: 'menu.integratedMaintenance.submenu.benchAppointment',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/service-order-labor-appointment',
        },
      },
      // Reports
      {
        id: 'service-order-report',
        label: 'menu.integratedMaintenance.submenu.reports',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/report',
        },
      },
      // Telemetry
      {
        id: 'service-order-telemetry',
        label: 'menu.integratedMaintenance.submenu.telemetry',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/integrated-maintenance/telemetry',
        },
      },
    ],
  },
  {
    id: 'manufacture',
    label: 'menu.manufacture.title',
    exact: true,
    activeClassName: 'active',
    icon: 'conveyor-belt',
    to: {
      pathname: '/manufacture',
    },
    children: [
      {
        id: 'manufacture-panel',
        label: 'menu.manufacture.submenu.panel',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/manufacture/panel',
        },
      },
      {
        id: 'manufacture-junk-report',
        label: 'menu.manufacture.submenu.junkReport',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/manufacture/junk-report',
        },
      },
    ],
  },
  // Alarm Management
  {
    id: 'alarm-management',
    label: 'menu.alarmManagement.title',
    exact: true,
    activeClassName: 'active',
    icon: 'siren-on',
    to: {
      pathname: '/alarm-management',
    },
  },
  // operational security
  {
    id: 'operational-security',
    label: 'menu.operationalSecurity.title',
    exact: true,
    activeClassName: 'active',
    icon: 'clipboard-list-check',
    to: {
      pathname: '/operational-security',
    },
    children: [
      {
        id: 'operational-security-impairments',
        label: 'menu.operationalSecurity.submenu.impairments',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/operational-security/impairment',
        },
      },
      {
        id: 'operational-security-routine-tests',
        label: 'menu.operationalSecurity.submenu.routineTests',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/operational-security/routine-tests',
        },
      },
    ],
  },
  // MOC - Management Of Changes
  {
    id: 'management-of-changes',
    label: 'menu.managementOfChanges.title',
    exact: true,
    activeClassName: 'active',
    icon: 'dolly',
    to: {
      pathname: '/management-of-changes',
    },
  },
  // Data Entry
  {
    id: 'data-entry',
    label: 'menu.dataEntry.title',
    exact: true,
    activeClassName: 'active',
    icon: 'inbox-in',
    to: {
      pathname: '/manual-collector',
    },
    children: [
      {
        id: 'manual-collection',
        label: 'menu.dataEntry.submenu.form',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/data-entry/manual-collector',
        },
      },
      {
        id: 'manual-input',
        label: 'menu.dataEntry.submenu.manual',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/data-entry/manual-input',
        },
      },
      {
        id: 'import-csv',
        label: 'menu.dataEntry.submenu.csv',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/data-entry/import-csv',
        },
      },
    ],
  },
  // Data Export
  {
    id: 'export',
    label: 'menu.export.title',
    exact: true,
    activeClassName: 'active',
    icon: 'inbox-out',
    to: {
      pathname: '/export',
    },
    children: [
      {
        id: 'data-export-csv',
        label: 'menu.export.submenu.data',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/export/data',
        },
      },
      {
        id: 'export-events',
        label: 'menu.export.submenu.event',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/export/event',
        },
      },
    ],
  },
  // Scale
  {
    id: 'scale',
    label: 'menu.scale.title',
    exact: true,
    activeClassName: 'active',
    icon: 'weight',
    to: {
      pathname: '/scale',
    },
  },
  // Operational Alerts
  {
    id: 'operational-alerts',
    label: 'menu.operationalAlerts.title',
    exact: true,
    activeClassName: 'active',
    icon: 'exclamation-triangle',
    to: {
      pathname: '/operational-alerts',
    },
  },
  // Consistency
  {
    id: 'consistency',
    label: 'menu.consistency.title',
    exact: true,
    activeClassName: 'active',
    icon: 'puzzle-piece',
    to: {
      pathname: '/consistency',
    },
    children: [
      {
        id: 'consistency-production-process',
        label: 'menu.consistency.submenu.productionAndProcess',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/consistency/production-process',
        },
      },
      {
        id: 'consistency-equipment-stopping',
        label: 'menu.consistency.submenu.equipmentStopping',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/consistency/equipment-stopping',
        },
      },
    ],
  },
  // Log
  {
    id: 'log',
    label: 'menu.log.title',
    exact: true,
    activeClassName: 'active',
    icon: 'list-alt',
    to: {
      pathname: '/log',
    },
  },
]

export const configLinks = [
  // Operational Structure
  {
    id: 'operational-structure',
    label: 'menu.configuration.operationalStructure.title',
    exact: true,
    activeClassName: 'active',
    icon: 'building',
    to: {
      pathname: '/settings/operational-structure',
    },
    children: [
      {
        id: 'parameters',
        label: 'menu.configuration.operationalStructure.submenu.parameters',
        exact: true,
        activeClassName: 'active',

        to: {
          pathname: '/settings/operational-structure/parameters',
        },
      },
      // Árvore de Elementos
      {
        id: 'elements-tree',
        label: 'menu.configuration.operationalStructure.submenu.elementsTree',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-structure/elements-tree',
        },
      },
      // Global Variables
      {
        id: 'settings-global-variables',
        label:
          'menu.configuration.operationalStructure.submenu.globalVariables',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/global-variables',
        },
      },
      // Token
      {
        id: 'settings-token',
        label: 'menu.configuration.operationalStructure.submenu.token',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/token',
        },
      },
      {
        id: 'settings-unit-measurement',
        label:
          'menu.configuration.operationalStructure.submenu.unitMeasurement',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/unit-measurement',
        },
      },
      {
        id: 'settings-barcode-label',
        label: 'menu.configuration.operationalStructure.submenu.barcodeLabel',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/barcode-label',
        },
      },
      {
        id: 'settings-product',
        label: 'menu.configuration.operationalStructure.submenu.product',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/product',
        },
      },
      {
        id: 'shifts-and-times',
        label: 'menu.configuration.operationalStructure.submenu.shiftsAndTimes',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-structure/shifts-and-times',
        },
      },
      {
        id: 'pdf-template',
        label: 'menu.configuration.operationalStructure.submenu.pdfTemplate',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-structure/pdf-template',
        },
      },
    ],
  },
  // Access Control
  {
    id: 'access-control',
    label: 'menu.configuration.accessControl.title',
    exact: true,
    activeClassName: 'active',
    icon: 'user-lock',
    to: {
      pathname: '/settings/access-control',
    },
    children: [
      {
        id: 'user-registration',
        label: 'menu.configuration.accessControl.submenu.userRegistration',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/access-control/user-registration',
        },
      },
      {
        id: 'user-group',
        label: 'menu.configuration.accessControl.submenu.userGroup',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/access-control/user-group',
        },
      },
      {
        id: 'access-profile',
        label: 'menu.configuration.accessControl.submenu.accessProfile',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/access-control/access-profile',
        },
      },
    ],
  },
  // Tags
  {
    id: 'settings-tags',
    label: 'menu.configuration.tags.title',
    exact: true,
    activeClassName: 'active',
    icon: 'tags',
    to: {
      pathname: '/settings/tags',
    },
    children: [
      {
        id: 'tags',
        label: 'menu.configuration.tags.submenu.tags',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/tags',
        },
      },
      {
        id: 'tags-groups',
        label: 'menu.configuration.tags.submenu.tagsGroup',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/tags/groups',
        },
      },
      {
        id: 'goals',
        label: 'menu.configuration.tags.submenu.goals',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/tags/goals',
        },
      },
    ],
  },
  // Event Manager
  {
    id: 'settings-event-manager-root',
    label: 'menu.configuration.eventManager.title',
    exact: true,
    activeClassName: 'active',
    icon: 'hourglass-start',
    to: {
      pathname: '/settings/event-manager',
    },
    children: [
      {
        id: 'settings-event-manager',
        label: 'menu.configuration.eventManager.submenu.frames',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/event-manager/frames',
        },
      },
      {
        id: 'event-manager-motives-group',
        label: 'menu.configuration.eventManager.submenu.motives',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/event-manager/motives-group',
        },
      },
      {
        id: 'event-manager-complementary-types',
        label: 'menu.configuration.eventManager.submenu.complementaryTypes',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/event-manager/complementary-types',
        },
      },
    ],
  },
  // Statistical Control
  {
    id: 'settings-statistical-control',
    label: 'menu.configuration.statisticalControl.title',
    exact: true,
    activeClassName: 'active',
    icon: 'chart-bar',
    to: {
      pathname: '/settings/statistical-control',
    },
    children: [
      {
        id: 'statistical-control-cards',
        label: 'menu.configuration.statisticalControl.submenu.cards',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/statistical-control/cards',
        },
      },
      {
        id: 'statistical-control-rules',
        label: 'menu.configuration.statisticalControl.submenu.rules',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/statistical-control/rules',
        },
      },
      {
        id: 'statistical-control-help',
        label: 'menu.configuration.statisticalControl.submenu.help',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/statistical-control/help',
        },
      },
      {
        id: 'statistical-control-diagnostic-list',
        label: 'menu.configuration.statisticalControl.submenu.diagnosticsList',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/statistical-control/diagnostic-list',
        },
      },
      {
        id: 'statistical-control-corrective-actions',
        label:
          'menu.configuration.statisticalControl.submenu.correctiveActions',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/statistical-control/corrective-actions',
        },
      },
    ],
  },
  // Control Loop Management
  {
    id: 'settings-control-loop-management',
    label: 'menu.configuration.controlLoopManagement.title',
    exact: true,
    activeClassName: 'active',
    icon: 'sliders-v',
    to: {
      pathname: '/settings/control-loop-management',
    },
  },
  // ROOT CAUSE
  {
    id: 'settings-root-cause',
    label: 'menu.configuration.rootCause.title',
    exact: true,
    activeClassName: 'active',
    icon: 'crosshairs',
    to: {
      pathname: '/settings/root-cause',
    },
    children: [
      {
        id: 'settings-root-cause-causes',
        label: 'menu.configuration.rootCause.submenu.causes',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/root-cause/causes',
        },
      },
      {
        id: 'settings-root-cause-rules',
        label: 'menu.configuration.rootCause.submenu.rules',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/root-cause/rules',
        },
      },
    ],
  },
  // OEE
  {
    id: 'settings-oee',
    label: 'menu.configuration.oee.title',
    exact: true,
    activeClassName: 'active',
    icon: 'tachometer-alt-average',
    to: {
      pathname: '/settings/oee',
    },
  },
  // Gestão Integrada de Manutenção
  {
    id: 'settings-integrated-maintenance',
    label: 'menu.configuration.integratedMaintenance.title',
    exact: true,
    activeClassName: 'active',
    icon: 'wrench',
    to: {
      pathname: '/settings/integrated-maintenance',
    },
    children: [
      // Peças e Serviços
      {
        id: 'integrated-maintenance-materials-services',
        label:
          'menu.configuration.integratedMaintenance.submenu.materialsServices',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/materials-services',
        },
      },
      // Modalidade
      {
        id: 'integrated-maintenance-modalities',
        label: 'menu.configuration.integratedMaintenance.submenu.modalities',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/modalities',
        },
      },
      // Status
      {
        id: 'integrated-maintenance-status',
        label: 'menu.configuration.integratedMaintenance.submenu.status',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/status',
        },
      },

      // Maintenance Activity
      {
        id: 'integrated-maintenance-maintenance-activity',
        label:
          'menu.configuration.integratedMaintenance.submenu.maintenanceActivity',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/maintenance-activity',
        },
      },
      // Priority
      {
        id: 'integrated-maintenance-priority',
        label: 'menu.configuration.integratedMaintenance.submenu.priority',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/priority',
        },
      },
      // Service Order Types
      {
        id: 'integrated-maintenance-service-order-types',
        label:
          'menu.configuration.integratedMaintenance.submenu.serviceOrderTypes',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/service-order-types',
        },
      },
      // Regras Mão de Obra
      {
        id: 'integrated-maintenance-labor-rules',
        label: 'menu.configuration.integratedMaintenance.submenu.laborRules',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/labor-rules',
        },
      },
      // Serviços de Preventiva
      {
        id: 'integrated-maintenance-services',
        label: 'menu.configuration.integratedMaintenance.submenu.services',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/services',
        },
      },
      // Frequency
      {
        id: 'integrated-maintenance-frequency',
        label: 'menu.configuration.integratedMaintenance.submenu.frequency',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/frequency',
        },
      },
      // Rotas de Inspeção
      {
        id: 'integrated-maintenance-inspection-routes',
        label:
          'menu.configuration.integratedMaintenance.submenu.inspectionRoutes',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/inspection-routes',
        },
      },
      // Cadastro de Relatórios
      {
        id: 'integrated-maintenance-report-registration',
        label:
          'menu.configuration.integratedMaintenance.submenu.reportRegistration',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integrated-maintenance/report-registration',
        },
      },
    ],
  },
  {
    id: 'settings-manufacture',
    label: 'menu.configuration.manufacture.title',
    exact: true,
    activeClassName: 'active',
    icon: 'conveyor-belt',
    to: {
      pathname: '/settings/manufacture',
    },
    children: [
      {
        id: 'manufacture-inspection-level',
        label: 'menu.configuration.manufacture.submenu.inspectionLevel',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/inspection-level',
        },
      },
      {
        id: 'manufacture-acceptable-quality-level',
        label: 'menu.configuration.manufacture.submenu.acceptableQualityLevel',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/acceptable-quality-level',
        },
      },
      {
        id: 'manufacture-complementary-table',
        label: 'menu.configuration.manufacture.submenu.complementaryTable',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/complementary-table',
        },
      },
      {
        id: 'manufacture-checklist-matrix',
        label: 'menu.configuration.manufacture.submenu.checklistApprovalMatrix',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/checklist-matrix',
        },
      },
      {
        id: 'manufacture-checklist-items',
        label: 'menu.configuration.manufacture.submenu.checklistItems',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/checklist-items',
        },
      },
      {
        id: 'manufacture-checklist',
        label: 'menu.configuration.manufacture.submenu.checklist',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/checklist',
        },
      },
      {
        id: 'manufacture-variation',
        label: 'menu.configuration.manufacture.submenu.variation',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/variation',
        },
      },
      {
        id: 'manufacture-defect',
        label: 'menu.configuration.manufacture.submenu.defect',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/defect',
        },
      },
      {
        id: 'manufacture-motive-group',
        label: 'menu.configuration.manufacture.submenu.motiveGroup',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/motive-group',
        },
      },
      {
        id: 'manufacture-status',
        label: 'menu.configuration.manufacture.submenu.status',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/status',
        },
      },
      {
        id: 'manufacture-src-order',
        label: 'menu.configuration.manufacture.submenu.srcOrder',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manufacture/src-order',
        },
      },
    ],
  },
  // Operational security
  {
    id: 'settings-operational-security',
    label: 'menu.configuration.operationalSecurity.title',
    exact: true,
    activeClassName: 'active',
    icon: 'clipboard-list-check',
    to: {
      pathname: '/settings/operationalSecurity/',
    },
    children: [
      {
        id: 'settings-operational-security-instrument-interlock',
        label:
          'menu.configuration.operationalSecurity.submenu.instrumentInterlock',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operationalSecurity/registration/instrument-interlock',
        },
      },
      {
        id: 'settings-operational-security-instrument-severity',
        label:
          'menu.configuration.operationalSecurity.submenu.instrumentSeverity',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operationalSecurity/registration/instrument-severity',
        },
      },
      {
        id: 'settings-operational-security-instrument-risk',
        label: 'menu.configuration.operationalSecurity.submenu.instrumentRisk',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operational-security/registration/instrument-risk',
        },
      },
      {
        id: 'settings-operational-security-instrument-frequency',
        label:
          'menu.configuration.operationalSecurity.submenu.instrumentFrequency',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operational-security/registration/instrument-frequency',
        },
      },
      {
        id: 'settings-operational-security-instrument-prefix',
        label:
          'menu.configuration.operationalSecurity.submenu.instrumentPrefix',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operational-security/registration/instrument-prefix',
        },
      },
      {
        id: 'settings-operational-security-instrument-mitigation-measures',
        label:
          'menu.configuration.operationalSecurity.submenu.instrumentMitigationMeasures',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname:
            '/settings/operational-security/registration/instrument-mitigation-measures',
        },
      },
      {
        id: 'settings-operational-security-impairment-rule',
        label: 'menu.configuration.operationalSecurity.submenu.impairmentRule',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-security/impairment-rule',
        },
      },
      {
        id: 'settings-operational-security-instrument',
        label: 'menu.configuration.operationalSecurity.submenu.instrument',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-security/instrument',
        },
      },
      {
        id: 'settings-operational-user-groups-tree',
        label: 'menu.configuration.operationalSecurity.submenu.userGroupTree',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-security/user-groups-tree',
        },
      },
      {
        id: 'settings-operational-approval-matrix',
        label: 'menu.configuration.operationalSecurity.submenu.approvalMatrix',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/operational-security/approval-matrix',
        },
      },
    ],
  },

  // MOC - Management Of Change
  {
    id: 'settings-management-of-change',
    label: 'menu.configuration.managementOfChanges.title',
    exact: true,
    activeClassName: 'active',
    icon: 'dolly',
    to: {
      pathname: '/settings/management-of-change',
    },
    children: [
      {
        id: 'settings-management-of-change-area-criticality',
        label: 'menu.configuration.managementOfChanges.submenu.areaCriticality',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/management-of-change/area-criticality',
        },
      },
      {
        id: 'settings-management-of-change-risk',
        label: 'menu.configuration.managementOfChanges.submenu.risk',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/management-of-change/risk',
        },
      },
      {
        id: 'settings-management-of-change-responsible-sector',
        label: 'menu.configuration.managementOfChanges.submenu.responsibleArea',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/management-of-change/responsible-sector',
        },
      },
      {
        id: 'settings-management-of-changes-approval-matrix',
        label: 'menu.configuration.managementOfChanges.submenu.approvalMatrix',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/management-of-changes/approval-matrix',
        },
      },
    ],
  },

  // Alerts
  {
    id: 'settings-alerts',
    label: 'menu.configuration.alerts.title',
    exact: true,
    activeClassName: 'active',
    icon: 'exclamation-triangle',
    to: {
      pathname: '/settings/alerts',
    },
    children: [
      {
        id: 'alerts-group',
        label: 'menu.configuration.alerts.submenu.alertsGroup',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/alerts/alerts-group',
        },
      },
      {
        id: 'alerts-configuration',
        label: 'menu.configuration.alerts.submenu.alertsConfiguration',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/alerts/alerts-configuration',
        },
      },
    ],
  },
  // Integration
  {
    id: 'settings-integration',
    label: 'menu.configuration.integration.title',
    exact: true,
    activeClassName: 'active',
    icon: 'plug',
    to: {
      pathname: '/settings/integration',
    },
    children: [
      {
        id: 'integration-api',
        label: 'menu.configuration.integration.submenu.api',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integration/api',
        },
      },
      {
        id: 'integration-system',
        label: 'menu.configuration.integration.submenu.system',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integration/system',
        },
      },
      {
        id: 'integration-laboratory',
        label: 'menu.configuration.integration.submenu.laboratory',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integration/laboratory',
        },
      },
      {
        id: 'integration-parameters',
        label: 'menu.configuration.integration.submenu.integrationParameters',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/integration/integration-parameters',
        },
      },
    ],
  },
  // Manual Collector
  {
    id: 'settings-manual-collection',
    label: 'menu.configuration.manualCollector.title',
    exact: true,
    activeClassName: 'active',
    icon: 'th',
    to: {
      pathname: '/settings/manual-collector',
    },
    children: [
      {
        id: 'manual-collection-group-collect',
        label: 'menu.configuration.manualCollector.submenu.collectGroup',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manual-collector/group-collect',
        },
      },
      {
        id: 'manual-collection-tags-collect',
        label: 'menu.configuration.manualCollector.submenu.collectTags',
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: '/settings/manual-collector/tags-collect',
        },
      },
    ],
  },
  // Logbook Group
  {
    id: 'logbook-group',
    label: 'menu.configuration.logbookGroup.title',
    exact: true,
    activeClassName: 'active',
    icon: 'clipboard',
    to: {
      pathname: '/settings/logbook-group',
    },
  },
]
